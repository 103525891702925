<div class="pageContainer">
    <div class="headerSection">
        <h1>Journey With Us</h1>
    </div>
    <div class="pageSection">
        <h2>Our Mission</h2>
        <p>
            Our mission is to build a comprehensive infrastructure and robust platform that empowers all human workers, robotic systems, organizations, and agentic AI agents to seamlessly integrate and leverage digital and 
            physical automation on a global scale. By focusing on innovation, scalability, and accessibility, we aim to create an ecosystem where automation technologies enhance efficiency, drive productivity, and enable 
            smarter decision-making across industries. As a model of excellence, we are committed to developing AI solutions that are reliable, intelligent, and capable of working in harmony with humans and machines alike, 
            driving progress and shaping the future of work.
        </p>
    </div>
    <div class="pageSection">
        <h2>The Market</h2>
        <p>Global AI and Automation is a massive, rapidly growing market with few true-players in the industry.  The global market is anticipated to be $3.527 Trillion by 2033, with an average CAGR of 303%.  The faster we accelerate;
             the more market share we will capture.
        </p>
        <mat-list>
            <mat-list-item><p><strong>Opportunity</strong></p></mat-list-item>
            <mat-list>
                <mat-list-item><p>Traditional automation requires specialized teams and a <strong>massive barrier of entry</strong>.</p></mat-list-item>
                <mat-list-item><p><strong>Difficult</strong> for small business owners to gain from current environement.</p></mat-list-item>
                <mat-list-item><p>Alternate providers either don't have the capability or are <strong>expensive, insecure, or time-consuming.</strong></p></mat-list-item>
            </mat-list>
            <mat-list-item><p><strong>Market Changes</strong></p></mat-list-item>
            <mat-list>
                <mat-list-item><p><strong>Rapidly burgeoning</strong> interest in automation and AI.</p></mat-list-item>
                <mat-list-item><p><strong>Low number of competitors</strong> as market matures.</p></mat-list-item>
                <mat-list-item><p>Capturing a <strong>loyal user base</strong> has huge revenue and IP potential.</p></mat-list-item>
            </mat-list>
            <mat-list-item><p><strong>Paragon's Positioning</strong></p></mat-list-item>
            <mat-list>
                <mat-list-item><p><strong>Early mover</strong> advantage.</p></mat-list-item>
                <mat-list-item><p><strong>Scalable</strong> across diverse industries and applications.</p></mat-list-item>
                <mat-list-item><p><strong>Low costs</strong> address target customer needs.</p></mat-list-item>
                <mat-list-item><p>Platform and <strong>LAM development for industries, services, and robotics</strong> to leverage.</p></mat-list-item>
            </mat-list>
        </mat-list>
    </div>
    <!-- <div class="pageSection">
        <h2>Market Assumptions</h2>
        <mat-list>
            <mat-list-item><p><strong>Exponential Market Growth with Significant Economic Impact</strong></p></mat-list-item>
            <mat-list>
                <mat-list-item><p>The AI market is projected to expant from USD 250.1 billion in 2023 to USD 3.527 trillion by 2033, reflecting a robust Compound Annual Growth Rate (CAGR) of 30.3%.</p></mat-list-item>
                <mat-list-item><p>AI is expected to contribute a 21% net increase to the United States GDP by 2030, underscoring its substantial global economic impact.</p></mat-list-item>
            </mat-list>
            <mat-list-item><p><strong>North America's Dominance and Global Government Adoption</strong></p></mat-list-item>
            <mat-list>
                <mat-list-item><p>North America will maintain its leading position in the AI market, capturing more than 38.9% of the global market share in 2023, driven by innovation, significant investments, and supportive regulatory environments.</p></mat-list-item>
                <mat-list-item><p>Governments worldwide are increasingly adopting AI to enhance public services and drive economic growth. For example, 58% of companies in China are deploying AI technologies, supported by government initiatives.</p></mat-list-item>
            </mat-list>
            <mat-list-item><p><strong>Machine Learning as the Leading Technology Fueling Robotics and Manufacturing Growth</strong></p></mat-list-item>
            <mat-list>
                <mat-list-item><p>Machine Learning (ML) remains the predominant technology within the AI sector, holding over 41.1% of the market share in 2023, powering diverse applications across industries.</p></mat-list-item>
                <mat-list-item><p>Manufacturing is anticipated to experience significant financial gains from AI integration, with expected benefits amounting to USD 3.8 trillion by 2035, enhancing automation and productivity.</p></mat-list-item>
                <mat-list-item><p>The robotics industry, including autonomous systems and self-driving vehicles, is poised for substantial growth fueled by AI advancements. The self-driving car industry alone is forecasted to exceed USD 600 billion in market valuation within the next five years.</p></mat-list-item>
            </mat-list>
            <mat-list-item><p><strong>Key Industries Driving AI Adoption and Job Creation</strong></p></mat-list-item>
            <mat-list>
                <mat-list-item><p>Healthcare, manufacturing, and robotics are major drivers of AI adoption, leveraging AI for diagnostics, automation, and optimization of processes.</p></mat-list-item>
                <mat-list-item><p>AI is projected to create approximately 97 million new jobs globally, mitigating concerns about workforce displacement due to automation and highlighting its role in economic development.</p></mat-list-item>
            </mat-list>
        </mat-list>
    </div> -->
    <div class="pageSection">
        <h2>Driving Value Across Industries</h2>
        <p>Our large action model and natural-language-to-automation 
            platform will enable use across
            digital and physical environments. With our
            secure infrastructure and localization,
            organizations can take full advantage of our
            LAM while continuously improving their
            workflows and model instance. Our LAM will
            be able to cross-pollinate from digital workers
            and agents to a physical digital-twin or
            autonomous system to expand into all market
            segments</p>
    </div>
    <div class="pageSection">
        <h2>Crossing the Chasm: Network Effect</h2>
        <p>Our strategy employs a freemium model to drive widespread
            adoption by offering free access to the platform, lowering barriers to
            entry, and allowing users to experience the benefits of process
            automation without upfront costs. As users engage with the platform,
            Prometheus Engine gathers diverse data from various tasks and
            workflows across industries, which enhances our central Large
            Action Model (LAM), making it smarter and more effective at
            automating a wide range of processes.
            This increased user interaction creates a compounding
            improvement effect, continuously enhancing the platform's ability to
            understand and automate complex tasks. The planned introduction of
            a marketplace amplifies this value by enabling users and service
            providers to create, share, and sell custom automation models,
            enriching the ecosystem with a wealth of ready-to-use solutions.
            The synergy of free access, an ever-improving centralized model,
            and a vibrant marketplace generates a powerful network effect.
            This not only drives broader adoption but also solidifies Paragon AI's
            position as a leader in process automation, facilitating its transition
            from early adopters to mainstream users.</p>
    </div>
    <div class="pageSection">
        <h2>Send Off</h2>
        <p>Join us, and let’s define what true outcome driven AI can do for the world, together.</p>
    </div>
</div>