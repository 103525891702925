<div id="navbar-light">
	<div class="logo">
		<img id="logo" (mouseenter)="swapLogo($event)" (mouseleave)="swapLogo($event)" src="../assets/blackLogoNoBack.png" (click)="goToHome()">
	</div>
  <!-- <ul class="nav-links">
    <li><a routerLink="/home">Home</a></li>
    <li><a routerLink="/howTo">How To</a></li>
    <li><a routerLink="/investors">Investors</a></li>
	  <li *ngIf="isAuthenticated$ | async"><a routerLink="/account">Account</a></li>
  </ul> -->
  <!-- <div *ngIf="!(isAuthenticated$ | async)">
	<ul class="nav-links" id="login">
	  <li><a id="loginLink" (click)="login()">Login</a></li>
	</ul>
  </div>
  <div *ngIf="isAuthenticated$ | async">
	<ul class="nav-links" id="logout">
	  <li><a (click)="logout()">Logout</a></li>
	</ul>	
  </div> -->
  	<div>
		<ul class="nav-links" id="email">
		<li><a>Stay Updated</a></li>
		</ul>
	</div>
</div>
<div class="spacer">

</div>
<router-outlet>
	
</router-outlet>
<footer class="footer">
	<mat-divider></mat-divider>
  <div id="iconContainer">
    <div id="footerIconsLeft">
      <mat-icon id="firstIconLeft" fontIcon="light_mode" (click)="changeToLight()"></mat-icon>
      <mat-icon fontIcon="dark_mode" (click)="changeToDark()"></mat-icon>
    </div>
    <div id="footerIconsRight">
      <mat-icon fontIcon="work" (click)="goToCareers()"></mat-icon>
      <mat-icon id="firstIconRight" fontIcon="contacts" (click)="goToContacts()"></mat-icon>
    </div>
  </div>
</footer>
<!-- <footer class="footer">
  <div class="footer-content">
    <div class="footer-links">
        <a routerLink="/about">About</a>
        <a routerLink="/contact">Contact</a>
    </div>
    <div class="footer-social">
        <a href="#" aria-label="Twitter">Twitter</a>
        <a href="#" aria-label="Instagram">Instagram</a>
    </div>
  </div>
  <div class="footer-bottom">
    <p>&copy; Paragon AI. All Rights Reserved.</p>
  </div>
</footer> -->