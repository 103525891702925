import { Component } from '@angular/core';

@Component({
  selector: 'app-paragon',
  standalone: false,
  templateUrl: './paragon.component.html',
  styleUrl: './paragon.component.scss'
})
export class ParagonComponent {

}
